.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

table, th, td {
  border: 1px solid black;
}